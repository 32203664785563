import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { HamburgerMenu } from '../hamburgerMenu/hamburger'
import { deviceMax } from "../../styles/media"
import { palette } from "../../styles/variables"
import * as headerStyles from './header.module.scss'


import "fontsource-bebas-neue" // Defaults to weight 400 with all styles included.
import "@fontsource/raleway/500.css"
import "@fontsource/roboto"

// const MenuIcon = styled.button`
//     position: fixed;
//     top: 2rem;
//     right: 2rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     width: 1.5rem;
//     height: 1.5rem;
//     background: transparent;
//     border: none;
//     cursor: pointer;
//     z-index: 5;

//     div {
//         width: 1.5rem;
//         height: .2rem;
//         background: white;
//         border-radius: 5px;
//         transform-origin: 1px;
//     }
// `

const ThermoWrap = styled.div`
    display: flex;
`;





  

const HeaderBox = styled.header`
    background-color: ${palette.avengerDarkPurple};
    color: ${palette.navTextColor};
    position: sticky;
    top: 0;
    height: 65px; 
    @media ${deviceMax.tablet} { 
        height: 55px;
    }  
    @media ${deviceMax.mobileL} { 
        height: 50px;
    } 
`;
const NavWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    height: 65px; 
    @media ${deviceMax.tablet} { 
        height: 55px;
    } 
    @media ${deviceMax.mobileL} { 
        height: 50px;
    }   
`;
const TitleBox = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid blue; */
`;
const TitleText = styled.h1`
    line-height: 0;
`;
const Title = styled(props => <Link {...props} />)`
    margin-left: 40px;
    text-decoration: none;
    color: ${palette.navTextColor};
    font-size: 2.2rem;
    font-family: "Bebas Neue";
  
    &:hover {
      color: ${palette.avengerYellow};
      transition: 0.2s;
    }
    @media ${deviceMax.desktop} {
        font-size: 2rem;
    }
    @media ${deviceMax.laptop} {
        margin-left: 20px;
    }
    @media ${deviceMax.mobileL} {
        font-size: 1.7rem;
    }
`;
  
const X = styled.span`
    font-size: 0.7em;
`;
const TriangleWrapper = styled.div`
    height: auto;
    /* border: 2px solid red; */
`; 
const TriangleShape = styled(props => <svg {...props} />)`
    width: 100%;
    height: 1.1rem;
    /* border: 2px solid blue; */
    /* @media ${deviceMax.tablet} { 
        height: 1.5rem;
    } */
`;

// const Triangle = styled(props => <path {...props} />)`
//     fill: ${palette.avengerYellow};
//     fill-opacity: 1;
//     filter: url(#shadow);
// `;
  
//   .triangle {
//     fill: $avenger-yellow;
//     fill-opacity: 1;
//     filter: url(#shadow);
//   }

const NavItem = styled(props => <Link {...props} />)`
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 4px;
    display: inline-block;
    color: ${palette.navTextColor};
    font-family: "Raleway";
    font-weight: 500;
    font-size: 0.7rem;
    margin-left: 3rem;
    height: 100%;
    text-decoration: none;
    text-align: center;
    padding: 0;
  
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${palette.avengerYellow};
    }
    &:before {
      opacity: 0;
      transform: translateY(-8px);
      transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
    }
    &:after {
      opacity: 0;
      transform: translateY(8px/2);
      transition: transform  0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity  0.2s;
    }
    &:hover,
    &:focus,
    &.${props => props.activeClassName} {
      &:before,
      &:after {
        opacity: 1;
        transform: translateY(0);
      }
      &:before {
        transition: transform  0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity  0.2s;
      }
      &:after {
        transition: transform 0s  0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s  0.2s;
      }
    }
`;




const Header = () => {
    return (
        <HeaderBox>
            <NavWrapper>
                <TitleBox>
                    <TitleText>
                        <Title to="/">Digital Diaries: R<X className={headerStyles.x}>x</X></Title>
                    </TitleText>
                </TitleBox>

                {/* put fake headerbox here */}

                <nav className={headerStyles.navBox}>
                    <ul className={headerStyles.navList}>
                        <li>
                            <NavItem activeClassName="any"  to ="/characters">The Characters</NavItem>
                        </li>
                        <li>
                            <NavItem activeClassName="any"  to ="/therapists">The Therapists</NavItem>
                        </li>
                        <li>
                            <NavItem activeClassName="any" to ="/goals">Our Goals</NavItem>
                        </li>
                        <li>
                            <NavItem activeClassName="any"  to ="/story">Our Story</NavItem>
                        </li>
                        <li>
                            <ThermoWrap>
                                <NavItem activeClassName="any"  css={`margin-right: 1rem;`}  to ="/resources">Need Help?</NavItem>
                                    <StaticImage
                                        src="../../images/thermo/thermo.png"
                                        alt="Line Thermometer"
                                        layout="constrained"
                                        placeholder="none"
                                        height={25}
                                    />
                            </ThermoWrap>
                        </li>
                    </ul>
                </nav>

                {/* <MenuIcon>
                    <div />
                    <div />
                    <div />
                </MenuIcon> */}
                <div className={headerStyles.menuWrapper}>
                    <HamburgerMenu>
                        {/* <ul className={headerStyles.menu}>
                            
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/characters">The Characters</Link>
                            </li>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/therapists">The Therapists</Link>
                            </li>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/goals">Our Goals</Link>
                            </li>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/story">Our Story</Link>
                            </li>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/resources">Need Help?</Link>
                            </li>
                        </ul> */}
                    </HamburgerMenu>
                </div>
                

                {/* <div className={headerStyles.menuIcon}>
                    <input className={headerStyles.inputBox} id="menuToggle" type="checkbox" />
                    
                    <div className={headerStyles.line}></div>
                    <div className={headerStyles.line}></div>
                    <div className={headerStyles.line}></div>

                        <ul className={headerStyles.menu}>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/characters">The Characters</Link>
                            </li>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/therapists">The Therapists</Link>
                            </li>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/goals">Our Goals</Link>
                            </li>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/story">Our Story</Link>
                            </li>
                            <li>
                                <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/resources">Need Help?</Link>
                            </li>
                        </ul>
                </div> */}
            </NavWrapper>
            <TriangleWrapper>
                <TriangleShape xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" preserveAspectRatio="none">
                    <defs>
                        <filter id="shadow">
                            <feDropShadow dx="0" dy="5" stdDeviation="10" flood-color="#000" flood-opacity=".8"/>
                        </filter>
                    </defs>
                    <path className={headerStyles.triangle} d="M0,2L100,100L100,0L0,0Z"></path>
                </TriangleShape>
            </TriangleWrapper>
        </HeaderBox>
    )
}

export default Header