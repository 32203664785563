import { motion } from 'framer-motion';
import React, { useState } from 'react';
import styled from 'styled-components'
import { MenuToggle } from './menuToggle';
import { NavMenu } from "./navMenu";
import { deviceMax } from "../../styles/media"

const HamburgerMenuContainer = styled.div `
    display: flex;
    `;

// const HamburgerIcon = styled.div `
//     color: ${({ reverseColor }) => reverseColor ? "#E3E0D6" : "#FAA62A"};
//     cursor: pointer;
//     z-index: 99;
//     transition: all 250ms ease-in-out;
//     `;

const MenuContainer = styled(motion.div) `
    min-width: 300px;
    width: 100%;
    height: 100%;
    margin-top: 65px;
    /* max-width: 102%; */
    background-color: #FAA62A;
    box-shadow: -2px 0 2px rgba(15, 15, 15, 0.3);
    z-index: 90;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(0);
    user-select: none;
    padding: 1em 2.5em;
    @media ${deviceMax.tablet} { 
        margin-top: 55px;
    } 
    @media ${deviceMax.mobileL} { 
        margin-top: 50px;
    } 
    
`;

const menuVariants = {
    open: {
        transform: "translateX(0em)",
        opacity: 1,
    },
    closed: {
        transform: "translateX(100em)",
        opacity: 0,
    }
}

const menuTransition = { duration: .2, ease: "easeInOut" }

export function HamburgerMenu(props) {

    const [isOpen, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!isOpen);
    }

    return (
        <HamburgerMenuContainer>
            
                <MenuToggle toggle={toggleMenu} isOpen={isOpen} />
            
            <MenuContainer 
                initial={false} 
                animate={isOpen ? "open" : "closed"} 
                variants={menuVariants} 
                transition={menuTransition}>
                
                <NavMenu isOpen={isOpen} />
                {/* {props.children} */}
                    
            </MenuContainer>
        </HamburgerMenuContainer>
    )

}