import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import * as footerStyles from "./footer.module.scss"
import { deviceMax } from "../../styles/media"

import "@fontsource/inter/400.css"

var underline = "#FFF";
var duration = "0.2s";
var distance = "8px";
var easeOutBack = "cubic-bezier(0.175, 0.885, 0.32, 1.275)"; 

const FooterContainer = styled.footer`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    align-content: center;  
    // height: 6rem;
    background-color: transparent;
`;

const BigWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #1B2029;
    width: 100%;
    @media ${deviceMax.laptop} {
        /* padding: 0 2rem; */
    }
`;
const DividerWrapper = styled.div`
    filter: drop-shadow(0px -30px 15px rgba(0,0,0,0.2));
  `;
  
const Divider = styled.div`
    position: relative;
    bottom: 2rem;
    width: 100%;
    height: 5rem;
    background-color: #222831;
    clip-path: polygon(0 25%, 100% 0, 100% 100%, 0 75%);
  `;
const LogoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media ${deviceMax.laptop} {
        flex-direction: column;
        margin-bottom: 20px;
    }
`;
const Logo = styled.div`
    margin: 40px;
    @media ${deviceMax.laptop} {
        margin: 20px;
    }
`;
const Sitemap = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media ${deviceMax.laptop} {
        padding: 0 4rem;
    }
`;
const ExternalLink = styled.a`
    cursor: pointer;
    transition: transform .1s ;
    &:hover {
        transform: scale(1.05);
    }
`;

const NavList = styled.ul`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    list-style: none;
    text-decoration: none;
    // text-transform: uppercase;
    text-align: center;
    @media ${deviceMax.tablet} { 
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`;

const NavItem = styled(props => <Link {...props} />)`
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Raleway";
    font-weight: 500;
    font-size: 0.75rem;
    margin: 0 2rem;
    height: 100%;
    text-decoration: none;
    text-align: center;
    padding: 0;
    
    // text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 4px;
  
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${underline};
    }
    &:before {
      opacity: 0;
      transform: translateY(-${distance});
      transition: transform 0s ${easeOutBack}, opacity 0s;
    }
    &:after {
      opacity: 0;
      transform: translateY(${distance}/2);
      transition: transform ${duration} ${easeOutBack}, opacity ${duration};
    }
    &:hover,
    &:focus,
    &.${props => props.activeClassName} {
      &:before,
      &:after {
        opacity: 1;
        transform: translateY(0);
      }
      &:before {
        transition: transform ${duration} ${easeOutBack}, opacity ${duration};
      }
      &:after {
        transition: transform 0s ${duration} ${easeOutBack}, opacity 0s ${duration};
      }
    }
    @media ${deviceMax.laptop} {
        font-size: .7rem;
        margin: 0 1rem;
    }
    @media ${deviceMax.tablet} {
        font-size: .7rem;
        margin: .8rem 0rem;
    }
`;

const Line = styled.hr`
    /* display: none; */
    width: 100%;
    /* max-width: 1072px; */
    max-width: 840px;
    background: white;
    opacity: .5;
    margin: 0;
    /* flex-grow: 1; */
    @media ${deviceMax.mobileL} { 
        width: 60%;
        
    }
`;
const CopyrightContainer = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    align-content: center;
    width: 100%;  
    height: 6rem;
    background-color: #12151A;
`;

const CopyrightText = styled.p`
    font-family: "Inter";
    font-weight: 400;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: white;
    text-align: center;
    margin: 0;

    @media ${deviceMax.tablet} {
		font-size: .65rem;
	}
    @media ${deviceMax.mobileL} {
		font-size: .5rem;
	}
`;

const Footer = () => {
    return (
        <FooterContainer>
            <BigWrapper>
                <DividerWrapper>
                    <Divider />
                </DividerWrapper>
                <LogoWrapper>
                    <Logo>
                        <StaticImage href="#"
                            src="../../images/yellow-composite.png"
                            alt="Trauma Avengers Logo"
                            layout="fixed"
                            width={200}
                            placeholder="blurred"
                        />
                    </Logo>
                    <ExternalLink href="https://www.nctsn.org" target="_blank">
                        <Logo>
                            <StaticImage
                                src="../../images/nctsn.png"
                                alt="NCTSN Logo"
                                layout="fixed"
                                height={100}
                                placeholder="blurred"
                                // width={200}
                            />
                        </Logo>
                    </ExternalLink>
                    <ExternalLink href="https://health.uconn.edu" target="_blank">
                        <Logo>
                            <StaticImage className={footerStyles.logo}
                                src="../../images/uconn.svg"
                                alt="UCONN Health Logo"
                                layout="fixed"
                                width={150}
                                placeholder="blurred"
                            />
                        </Logo>
                    </ExternalLink>
                </LogoWrapper>
                <Sitemap>
                    <NavList>
                            <li>
                                <NavItem activeClassName="any" to ="/">Home</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/characters">The Characters</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/therapists">The Therapists</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/goals">Our Goals</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/story">Our Story</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/resources">Need Help?</NavItem>
                            </li>
                        </NavList>
                        <Line />
                        <NavList>
                            <li>
                                <NavItem activeClassName="any" to ="/characters/samantha">Samantha</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/characters/gabby">Gabby</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/characters/joshua">Joshua</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/characters/rosa">Rosa</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/characters/james">James</NavItem>
                            </li>
                            <li>
                                <NavItem activeClassName="any" to ="/characters/isabella">Isabella</NavItem>
                            </li>
                        </NavList>
                </Sitemap>
            </BigWrapper>
            <CopyrightContainer>
                <CopyrightText>Copyright © 2021 | Digital Diaries: Rx Project | All Rights Reserved</CopyrightText>
            </CopyrightContainer>
        </FooterContainer>
    )
}

export default Footer