import React from "react";
import { Link } from 'gatsby'
import styled from "styled-components";
import { motion } from "framer-motion";

import * as headerStyles from '../header/header.module.scss'
import "@fontsource/raleway/500.css"

const NavMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NavList = styled.ul`
  padding: 0 0.8em;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
`;

const NavLink = styled(motion.li)`
  display: inline-block;
  color: #e3e0d6;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  
  opacity: 1;

  transition: opacity 300ms ease-in-out;

  

`;

const variants = {
  show: {
    transform: "translateX(0em)",
    opacity: 1,
  },
  hide: {
    transform: "translateX(5em)",
    opacity: 0,
  },
};

export function NavMenu({ isOpen }) {
  return (
    <NavMenuContainer>
      <NavList>
        <NavLink
          initial={false}
          animate={isOpen ? "show" : "hide"}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.3, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.05, duration: 0.05 },
            },
          }}
        >
          <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/characters">The Characters</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? "show" : "hide"}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.4, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.1, duration: 0.05 },
            },
          }}
        >
          <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/therapists">The Therapists</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? "show" : "hide"}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.5, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.15, duration: 0.05 },
            },
          }}
        >
          <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/goals">Our Goals</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? "show" : "hide"}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.6, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.2, duration: 0.05 },
            },
          }}
        >
          <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/story">Our Story</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? "show" : "hide"}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.7, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.25, duration: 0.05 },
            },
          }}
        >
          <Link className={headerStyles.menuItem} activeClassName={headerStyles.navItemActive} to ="/resources">Need Help?</Link>
        </NavLink>
      </NavList>
    </NavMenuContainer>
  );
}