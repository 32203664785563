import React from 'react';
import { Link } from 'gatsby'
import styled from "styled-components";
import { GatsbyImage } from 'gatsby-plugin-image'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { palette } from "../../styles/variables.js"
import { deviceMin, deviceMax } from "../../styles/media"
import black_paper from "../../images/textures/black-paper.png"
import purple_hatch from "../../images/textures/purple-hatch.jpg"

export const isBrowser = () => typeof window !== "undefined"

export const PageContainer = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
`;
export const ContentWrapper = styled.div`
    position: relative;
    margin-top: -10rem;
    padding-top: 6rem;
`;
export const DeepBackground = styled.div`
    position: relative;
    width: 100%;
    height: 30rem;
    background-image: url(${purple_hatch});
    margin-bottom: -24rem;
    z-index: -1;
`;
export const HeadingWrapper = styled.div`
    position: relative;
    width: 100%;
    /* height: auto; */
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    /* border: 2px solid pink; */
    @media ${deviceMax.laptop} {
        margin-top: 5rem;
        margin-bottom: 0;
        /* padding: 0 3rem; */
    } 
`;
export const Title = styled.h1`
    font-family: "Bebas Neue";
    font-size: 7rem;
    color: ${props => props.color};
    margin: 0 0 0 0;
    letter-spacing: .2rem;
    text-align: center;
    @media ${deviceMax.laptop} {
        font-size: 6rem;
    }
    @media ${deviceMax.tablet} { 
        font-size: 5rem;
    }
    @media ${deviceMax.mobileL} { 
        font-size: 4rem;
    }
    @media ${deviceMax.mobileM} { 
        font-size: 3.2rem;
    }
`;
export const Line = styled.div`
    width: 65%;
    opacity: .8;
    @media ${deviceMax.laptop} {
        width: 75%;
    }
    @media ${deviceMax.tablet} { 
        width: 90%;
        opacity: .9;
    }
`;
export const SubTitle = styled.h2`
    font-family: "Raleway";
    font-size: 3rem;
    font-weight: 400;
    color: ${palette.text90};
    text-transform: uppercase;
    text-align: center;
    width: 65%;
    @media ${deviceMax.laptop} {
        font-size: 2.5rem;
    }
    @media ${deviceMax.tablet} { 
        font-size: 2rem;
        width: 90%;
    }
    @media ${deviceMax.mobileL} { 
        font-size: 1.5rem;
        width: 90%;
    }
`;
// export const SolidDivider = styled.div`
//     width: 100%;
//     height: 4rem;

//     background: ${palette.avengerDarkPurple};
//     /* clip-path: polygon(0 2rem, 100% 0, 100% 100%, 0 100%); */
//     filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
// `;
export const SolidBackground = styled.div`
    background: ${palette.backgroundDarkPurple};
    width: 100%;
    height: 100%;
    /* border: 2px solid red; */
    z-index: 10;
    /* clip-path: polygon(0 2rem, 100% 0, 100% 100%, 0 100%); */
    &:fullscreen {
        border-color: yellow;
    }
`;
export const TextureBackground = styled.div`
    background-image: url(${black_paper});
    width: 100%;
    height: auto;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    @media ${deviceMax.laptop} { 
        /* padding: 0 5rem; */
    }
    @media ${deviceMax.mobileL} { 
        padding: 0;
    }
`;
export const Container = styled.div`
    width: 1072px;
    max-width: 1072px;
    height: auto;
    margin-bottom: 6rem;
    padding-top: 2rem;
    /* padding-top: 4rem; */
    /* border: 2px solid red; */
    /* max-width: 100vw; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; */
    @media ${deviceMax.laptopL} { 
        margin: 0 1rem 6rem;
        width: auto;
        padding-top: 2rem;
        /* padding: 2rem 1rem 0; */
        /* overflow-x: hidden; */
    }
    @media ${deviceMin.laptop} { 
        margin: 0 2rem 6rem;
    }
    @media ${deviceMax.laptop} { 
        width: auto;
        margin: 0 1rem 6rem;

        /* padding: 2rem 1rem 0; */
        /* overflow-x: hidden; */
    }
    @media ${deviceMax.tablet} { 
        width: 100vw;
        margin: 0 0rem 6rem;
        /* padding-top: 2rem; */
        padding: 2rem 1rem 0;
        /* overflow-x: hidden; */
    }
    @media ${deviceMax.mobileL} { 
        width: 100vw;
        padding-top: 2rem;
        overflow-x: hidden;
    }
`;
export const MediaContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5rem 6rem;
    background: ${props => props.background};
    border-radius: 5px;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.mobileL} { 
        margin: 0 0rem 4rem;
        filter: drop-shadow(0px 5px 10px rgba(0,0,0,.5));
    }
    @media ${deviceMax.mobileL} and (orientation: landscape) {
        /* border: 2px solid red; */
    }
    @media ${deviceMax.mobileS} {
        margin: 0 0rem 3rem;
    }
`;
export const Thumbnail = styled(props => <GatsbyImage {...props} />)`
    border-radius: 5px 5px 0 0;    
`;
export const InfoBox = styled.div`
    height: auto;
    display: flex;
    border-top: 4px solid ${props => props.color};
    @media ${deviceMax.tablet} { 
        height: auto;
        border-top-width: 2px;
    }
`;
export const BigNumber = styled.div`
    width: 9rem;
    padding: .5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 4px solid ${props => props.color};
    @media ${deviceMax.tablet} { 
        width: auto;
        padding: .5rem;
        border-right-width: 2px;
    }
`;
export const Top = styled.div`
    /* height: 25%; */
    display: flex;
    justify-content: center;
    margin-top: 0rem;
    margin-bottom: 0rem;
    /* align-items: center; */
    font-family: "Raleway";
    font-weight: 800;
    font-size: 1.5rem;
    color: ${palette.text90};
    /* @media ${deviceMax.mobileL} and (orientation: landscape) {
        color: red;
    } */
    @media ${deviceMax.tablet} { 
        font-size: 1rem;
        line-height: .9rem;
        height: 1rem;
        margin: 0;
    }

`;
export const Bottom = styled.div`
    height: 4rem;
    /* padding-bottom: .9rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Work Sans";
    font-weight: 800;
    font-size: 4.5rem;
    color: ${palette.text90};
    @media ${deviceMax.tablet} { 
        height: 2rem;
        font-size: 3rem;
    }

`;
export const MediaText = styled.div`
    width: calc(100% - 9rem);
    padding: .5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${deviceMax.mobileL} { 
        width: auto;
        padding: .5rem;
        
    }
`;
export const MediaTitle = styled.h3`
    font-family: "Raleway";
    font-weight: 800;
    font-style: italic;
    font-size: 1.8rem;
    margin: 0 0 .6rem 0;
    color: ${palette.text90};
    @media ${deviceMax.tablet} { 
        font-size: 1rem;   
    }
`;
export const Authors = styled.h4`
    font-family: "Raleway";
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0 0 .6rem 0;
    color: ${palette.text90};
    @media ${deviceMax.tablet} { 
        font-size: .7rem;
        margin: 0 0 .3rem 0;
    }
`;
export const Description = styled.h4`
    font-family: "Raleway";
    font-weight: 500;
    font-size: .9rem;
    margin: 0;
    color: ${palette.text90};
    @media ${deviceMax.tablet} { 
        font-size: .6rem;
    }
`;
export const Button = styled(props => <Link {...props} />)`
    width: 15rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .7rem 1.1rem;
    margin: 0rem;
    z-index: 10;
    color: #FFF;
    background-color: #0F132E;
    border: 1px solid #4E7187;
    border-radius: 2rem;
    font-family: "Raleway";
    font-size: .8rem;
    font-weight: 500;
    letter-spacing: .06rem;
    text-decoration: none;
    // font-size: 2rem;
    transform: scale(1);

    text-transform: uppercase;

    cursor: pointer;
    transition: .2s ease-out;

    &:hover {
        transition: .1s ease-in;
        transform: scale(1.02);
        border-color: #b9d2e2;
        box-shadow: 0px 1px 5px #000;           
    }
`;
export const ButtonToggle = styled.button`
    width: 15rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .7rem 1.1rem;
    margin: 0rem;
    z-index: 10;
    color: #FFF;
    background-color: #0F132E;
    border: 1px solid #4E7187;
    border-radius: 2rem;
    font-family: "Raleway";
    font-size: .8rem;
    font-weight: 500;
    letter-spacing: .06rem;
    text-decoration: none;
    // font-size: 2rem;
    transform: scale(1);

    text-transform: uppercase;

    cursor: pointer;
    transition: .2s ease-out;

    &:hover {
        transition: .1s ease-in;
        transform: scale(1.02);
        border-color: #b9d2e2;
        box-shadow: 0px 1px 5px #000;           
    }
`;

export const BumperOffset = styled.div`
    margin-top: -16rem;
`;

export const Bumper = styled.div`
    position: sticky;
    top: 88vh;
    left: 0;
    /* border: 2px solid blue;  */
    height: 5rem;
    width:15rem;
    margin: 10rem 0 2rem 1rem;
    align-self: flex-start;
    z-index: 10;
    @media ${deviceMax.mobileS} {
        top: 83vh;
    } 
`;


export const EntryColumn = styled.div`
    /* position: relative; */
    width: 15rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    z-index: 1;
    @media ${deviceMax.laptop} {
        width: auto;
    } 
    @media ${deviceMax.tablet} { 
        width: 100%;
        background: ${props => props.background};
        border-radius: 5px;
        margin: 0 0 2rem;
        overflow-x: scroll;
        &:after {
            content: '';
            position: absolute;
            right: 1rem;
            width: 2rem;
            height: 12.8rem;
            /* padding-right: .7rem; */
            background: ${props => props.fade};
            border-radius: 0 5px 5px 0;
            z-index: 3;
        }
    }
    @media ${deviceMax.mobileL} { 
        width: 100%;
        background: ${props => props.background};
        /* height: 15rem; */
        /* border: 2px solid blue; */
        border-radius: 5px;
        margin: 0 0 2rem;
        overflow-x: scroll;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 2rem;
            height: 12.8rem;
            /* padding-right: .7rem; */
            background: ${props => props.fade};
            border-radius: 0 5px 5px 0;
            z-index: 3;
        }
    }
    
`;
export const Drawing = styled(props => <GatsbyImage {...props} />)`
    /* position: relative; */
    /* top: -20rem; */
    margin-top: -20rem;
    left: 0;
    /* width: 15rem; */
    height: 25rem;
    z-index: 1;
    filter: drop-shadow(0px 10px 10px rgba(0,0,0,.6));
    /* height: auto; */
    @media ${deviceMax.tablet} { 
        display: none;
        
    }
`;
export const Entries = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 15rem;
    /* height: auto; */
    margin-top: -5rem;
    background: ${props => props.background};
    border-radius: 5px;
    padding: .7rem;
    z-index: 2;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.laptop} {
        width: 100%;
        filter: drop-shadow(0px 5px 10px rgba(0,0,0,1));
        /* padding: 0; */
    }
    @media ${deviceMax.tablet} { 
        display: flex;
        flex-direction: row;
        width: auto;
        /* overflow-x: scroll; */
        /* overflow-y: hidden; */
        margin: 0;
        background: none;
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }
`;
export const EntryLink = styled(props => <AnchorLink {...props} />)`
    text-decoration: none;
`;
export const Entry = styled.div`
    width: 100%;
    margin: 0 0 1.5rem;
    text-decoration: none;
    /* border: 2px solid red; */
    cursor: pointer;
    transition: .2s ease-in-out;
    
    &:hover {
        transform: scale(1.02);
        filter: drop-shadow(0px 20px 15px rgba(0,0,0,.3));
    }
    @media ${deviceMax.laptop} {
        width: 11rem;
    } 
    @media ${deviceMax.tablet} { 
        width: 15rem;
        margin: 0 1rem 0 0; 

        
        
    }
    
`;
export const EntryThumbnail = styled(props => <GatsbyImage {...props} />)`
    border: 2px solid #FFF;
    border-radius: 5px;
    
`;
export const EntryNumber = styled.h4`
    font-family: "Raleway";
    text-transform: uppercase;
    margin: .5rem 0;
    color: ${palette.text90};
    transition: .2s ease-in-out;
    &:after {
        content: '';
        width: 0;
        height: 1px;
        display: block;
        background: #FFF;
        opacity: 0;
        transition: 300ms;
    }
    ${Entry}:hover & {
        &:after {
            opacity: 1;
            width: 75%;
        }
    }
    @media ${deviceMax.laptop} { 
        margin: .5rem 0 .2rem;
    }
`;
export const EntryTitle = styled.h5`
    font-family: "Raleway";  
    font-weight: 500;
    font-size: .8rem;
    margin: 0;
    color: ${palette.text90};
    transition: .2s ease-in-out;
    &:after {
        content: '';
        width: 0;
        height: 1px;
        display: block;
        background: #FFF;
        opacity: 0;
        transition: 300ms;
    }
    ${Entry}:hover & {
        &:after {
            opacity: 1;
            width: 100%;
        }
    }
`;

const lineLinkVariables = {
    underline: "#FFF",
    duration: "0.2s",
    distance: "8px",
    easeOutBack: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
}
export const LineLink = styled(props => <Link {...props} />)`
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Raleway";
    font-weight: 500;
    /* font-size: 0.75rem; */
    margin: 0 2rem;
    height: 100%;
    text-decoration: none;
    text-align: center;
    padding: 0;
    
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 4px;
  
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${lineLinkVariables.underline};
    }
    &:before {
      opacity: 0;
      transform: translateY(-"${lineLinkVariables.distance}");
      transition: transform 0s ${lineLinkVariables.easeOutBack}, opacity 0s;
    }
    &:after {
      opacity: 0;
      transform: translateY("${lineLinkVariables.distance} / 2");
      transition: transform ${lineLinkVariables.duration} ${lineLinkVariables.easeOutBack}, opacity ${lineLinkVariables.duration};
    }
    &:hover,
    &:focus,
    &.nav-item-active {
      &:before,
      &:after {
        opacity: 1;
        transform: translateY(0);
      }
      &:before {
        transition: transform ${lineLinkVariables.duration} ${lineLinkVariables.easeOutBack}, opacity ${lineLinkVariables.duration};
      }
      &:after {
        transition: transform 0s ${lineLinkVariables.duration} ${lineLinkVariables.easeOutBack}, opacity 0s ${lineLinkVariables.duration};
      }
    }
`;