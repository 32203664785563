import React from 'react'

import Header from '../header/header'
import Footer from '../footer/footer'
import '../../styles/index.scss'
import * as layoutStyles from './layout.module.scss'

const Layout = (props) => {
    return (
        <div className={layoutStyles.rootContainer}>
            <div className={layoutStyles.headerContainer}>
                <Header />
            </div>
            <div className={layoutStyles.mainContent}>
                <div className={layoutStyles.content}>
                    {props.children}
                </div>               
            </div>
            <Footer />
        </div>
    )
}

export default Layout