export const palette = {
    // Global Color Palette Variables
    avengerYellow: "#FAA62A",
    avengerDarkPurple: "#140f29",
    backgroundDarkPurple: "#010315",
    navTextColor: "#e3e0d6",

    // SHADOWS
    blackAlpha60: "rgba(0, 0, 0, 0.6)",

    // TEXT COLORS
    text90: "rgba(255, 255, 255, 0.9)",
    titleWhite: "rgba(255, 255, 255, 0.8)",

    // BORDERS
    warmWhite: "#D0CDC6",
    testing: "left",

    // SAMANTHA
    samanthaPrimary: "#164734",
    samanthaDesat: "#233e34",
    samanthaHighlight: "#8cbcad",
    samanthaLowlight: "#0b291d",
    // GABBY
    gabbyPrimary: "#8e7816",
    gabbyDesat: "#b19f5b",
    gabbyHighlight: "#ffefac",
    gabbyLowlight: "#7c6117",
    // JOSHUA
    joshuaPrimary: "#265564",
    joshuaDesat: "#3a5e6a",
    joshuaHighlight: "#6cada8",
    joshuaLowlight: "#092731",
    // ROSA
    rosaPrimary: "#97301f",
    rosaDesat: "#985347",
    rosaHighlight: "#ce9f5f",
    rosaLowlight: "#812a1b",
    // JAMES
    jamesPrimary: "#1d3453",
    jamesDesat: "#384352",
    jamesHighlight: "#689bad",
    jamesLowlight: "#0e1a29",
    // ISABELLA
    isabellaPrimary: "#832c3d",
    isabellaDesat: "#7b4c55",
    isabellaHighlight: "#b1848d",
    isabellaLowlight: "#40171f",
}