// extracted by mini-css-extract-plugin
export var headerBox = "header-module--header-box--wa3ZY";
export var navWrapper = "header-module--nav-wrapper--1Ru4j";
export var titleBox = "header-module--title-box--2d8uk";
export var navBox = "header-module--nav-box--2Gww3";
export var title = "header-module--title--aij_O";
export var x = "header-module--x--tEPrJ";
export var navList = "header-module--nav-list--IpMFD";
export var navItem = "header-module--nav-item--2qBde";
export var triangleWrapper = "header-module--triangle-wrapper--PmfIn";
export var triangleShape = "header-module--triangle-shape--1zrf_";
export var triangle = "header-module--triangle--3fQFN";
export var navItemActive = "header-module--nav-item-active--2Yip4";
export var menuWrapper = "header-module--menu-wrapper--1xLyC";
export var menu = "header-module--menu--1LBBy";
export var menuItem = "header-module--menu-item--b9s6h";
export var menuIcon = "header-module--menu-icon--22Gfx";